<template>
  <div>
    <template>
      <div>
        <!-- MODAL ADD REWARD / PUNISHMENT -->
        <a-modal
          title="Add Reward / Punishment"
          okText="Add"
          :visible="modalVisible"
          :confirm-loading="confirmLoading"
          @ok="handleOkCreate"
          @cancel="handleCancel"
        >
          <a-form-item label="Type">
            <a-select
              size="large"
              style="width: 100%; height: 40px"
              @change="handleSelectType"
              placeholder="Select Type"
            >
              <a-select-option
                v-for="item in types"
                :key="item.key"
                :value="item.value"
                >{{ item.value }}</a-select-option
              >
            </a-select>
          </a-form-item>
          <a-form-item label="Description">
            <a-select
              :disabled="!selectType"
              size="large"
              style="width: 100%; height: 40px"
              @change="handleSelectDescription"
              placeholder="Select Description"
            >
              <a-select-option
                v-for="item in filterType"
                :key="item.id"
                :value="item.id"
                >{{ item.description }}</a-select-option
              >
            </a-select>
          </a-form-item>
          <a-form-item label="Score">
            <a-input-number size="large" class="mt-3" :value="selectedScore" disabled/>
          </a-form-item>
          <a-form-item label="Frequency">
            <a-input-number size="large" class="mt-3" :value="this.frequency" :disabled="!selectedScore" @change="(e) => handleChange(e, 'Frequency')"/>
          </a-form-item>
          <a-form-item label="Note">
            <a-input size="large" class="mt-3" placeholder="Input R/P Note" :value="this.note" :disabled="!selectedScore" @change="(e) => handleChange(e.target.value, 'Note')"/>
          </a-form-item>
        </a-modal>
        <!-- MODAL ADD REWARD / PUNISHMENT -->
        <!-- MODAL EDITING REWARD & PUNISHMENT -->
        <a-modal
          v-if="dataGeneralReport.kelas_tahun_ajaran"
          :visible="modalEditVisible"
          :confirm-loading="confirmLoading"
          @ok="handleOkUpdate"
          @cancel="handleCancel"
          :maskStyle="{
            opacity: 0.5,
            backgroundColor: 'black',
          }"
          okText="Update"
          :okButtonProps="{
            props: {
              shape: 'round',
            },
          }"
          :cancelButtonProps="{
            props: {
              shape: 'round',
            },
          }"
        >
          <h3 slot="title" style="color: #faad14">
            <a-icon style="font-size: 1.2em" type="warning" /> EDIT Reward &
            Punishment CONFIRMATION
          </h3>
          <p>
            Are you sure you want to update these reward & punishment? The old data for {{ dataGeneralReport.kelas_tahun_ajaran.murid.nama }} will
            be replaced once you update.
          </p>
        </a-modal>
        <!-- MODAL EDITING REWARD & PUNISHMENT -->
      </div>
    </template>
    <!-- VIEW -->
    <div v-if="dataGeneralReport.kelas_tahun_ajaran" class="align-items-center">
      <div class="d-flex flex-column flex-md-row">
        <div class="w-100 w-lg-75">
          <a-divider orientation="left">
            <h3>{{ dataGeneralReport.kelas_tahun_ajaran.murid.nama }}</h3>
          </a-divider>
          <p>In this page you can add, edit, delete this student Reward & Punishment</p>
        </div>
        <div class="d-flex pt-3">
          <template v-if="!editReportRP">
            <a-button
              shape="round"
              type="primary"
              icon="plus"
              class="mb-3 mr-2"
              @click.prevent="showModal"
              >ADD</a-button>
            <a-button
              shape="round"
              :type="!dataTable.length ? 'default' : 'primary'"
              icon="edit"
              class="mb-3"
              :disabled="!dataTable.length"
              @click.prevent="toEditReportRP"
              >EDIT</a-button>
          </template>
          <template v-else>
            <a-button
              @click.prevent="cancelEdit"
              :loading="loadingCancel"
              type="danger"
              shape="round"
              icon="close"
              class="mb-3 mb-lg-0 mr-0 mr-md-3"
              >CANCEL</a-button
            >
            <a-button
              @click.prevent="saveEdit"
              shape="round"
              icon="save"
              style="background-color: #41b883; color: white"
              >SAVE</a-button
            >
          </template>
        </div>
      </div>
      <!-- SHOW TABLE REWARD & PUNISHMENT FOR THIS STUDENT -->
      <div class="pt-3">
        <a-table
          bordered
          size="large"
          :pagination="{ pageSize: 10, hideOnSinglePage: true }"
          :loading="loadingTable"
          :columns="columnsTableRP"
          :data-source="dataTable"
        >
        <div
          v-if="editReportRP"
          slot="frequency"
          slot-scope="text, record"
        >
          <a-input-number
            size="large"
            :value="text"
            @change="
              (e) => handleEditChange(e, record.key, 'frequency')
            "
          />
        </div>
        <div v-else slot="frequency" slot-scope="text">
          <span>{{ text }}</span>
        </div>
        <div
          v-if="editReportRP"
          slot="note"
          slot-scope="text, record"
        >
          <a-input
            :value="text"
            size="large"
            @change="
              (e) => handleEditChange(e.target.value, record.key, 'note')
            "
          />
        </div>
        <div v-else slot="note" slot-scope="text">
          <span>{{ text }}</span>
        </div>
        <div slot="action" slot-scope="text, record">
          <a-button :disabled="editReportRP" size="default" :loading="loadingDelete" :value="text" @click.prevent="(e) => deleteRewardPunishment(e.target.value, record.key)" shape="round" type="danger" icon="delete">DELETE</a-button>
        </div>
        </a-table>
      </div>
      <!-- SHOW TABLE REWARD & PUNISHMENT FOR THIS STUDENT -->
    </div>
    <!-- VIEW -->
  </div>
</template>

<script>
const columnsTableRP = [
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    scopedSlots: { customRender: 'type' },
    align: 'center',
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
    scopedSlots: { customRender: 'description' },
    align: 'center',
  },
  {
    title: 'Score',
    dataIndex: 'score',
    key: 'score',
    scopedSlots: { customRender: 'score' },
    align: 'center',
  },
  {
    title: 'Frequency',
    dataIndex: 'frequency',
    key: 'frequency',
    scopedSlots: { customRender: 'frequency' },
    align: 'center',
  },
  {
    title: 'Total Score',
    dataIndex: 'totalScore',
    key: 'totalScore',
    scopedSlots: { customRender: 'totalScore' },
    align: 'center',
  },
  {
    title: 'Note',
    dataIndex: 'note',
    key: 'note',
    scopedSlots: { customRender: 'note' },
    align: 'center',
  },
  {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
    scopedSlots: { customRender: 'action' },
    align: 'center',
  },
]

export default {
  name: 'report-rp',
  data() {
    return {
      loadingCancel: false,
      loadingDelete: false,
      editReportRP: false,
      modalVisible: false,
      modalEditVisible: false,
      confirmLoading: false,
      loadingTable: false,
      columnsTableRP,
      dataTable: [],
      savedData: [],
      dataMasterRP: [],
      types: [
        {
          key: 1,
          value: 'Reward',
        },
        {
          key: 2,
          value: 'Punishment',
        },
      ],
      selectType: null,
      filterType: [],
      selectDescription: null,
      selectedScore: null,
      frequency: null,
      note: null,
      idGeneralReport: this.$route.params.id,
      dataGeneralReport: {},
    }
  },
  methods: {
    async fetchStudentRP() {
      this.loadingTable = true
      try {
        this.dataGeneralReport = await this.$store.dispatch('homeroom/FETCH_REWARD_PUNISHMENT_BY_GENERAL_REPORT', { idGeneralReport: this.idGeneralReport })
        const mapTable = this.dataGeneralReport.report_reward_punishments.map(
          (row) => {
            const type = row.reward_punishment.type
            const typeCapitalized = type.charAt(0).toUpperCase() + type.slice(1)
            return {
              key: row.id,
              id_general_report: row.id_general_report,
              id_reward_punishment: row.id_reward_punishment,
              type: typeCapitalized,
              description: row.reward_punishment.description,
              score: row.reward_punishment.score,
              frequency: row.frequency,
              totalScore: row.reward_punishment.score * row.frequency,
              note: row.note || '-',
            }
          },
        )
        this.dataTable = mapTable
        this.savedData = this.dataTable
        this.loadingTable = false
        return new Promise((resolve, reject) => resolve())
      } catch (err) {
        this.loadingTable = false
        return new Promise((resolve, reject) => reject(err))
      }
    },
    showModal() {
      this.modalVisible = true
    },
    handleChange(value, column) {
      if (column === 'Note') {
        this.note = value
      } else if (column === 'Frequency') {
        if (isNaN(Number(value))) {
          setTimeout(() => {
            this.$notification.error({
              message: 'Error',
              description: `This, ${value}'s Frequency must be numbers !!`,
            })
            this.modalConfirm = false
            this.visible = false
          }, 1000)
        } else {
          this.frequency = value
        }
      }
    },
    handleOkCreate() {
      const data = {
        type: 'add',
        id_general_report: this.dataGeneralReport.id,
        id_reward_punishment: this.selectDescription,
        frequency: this.frequency,
        note: this.note,
      }
      this.$emit('child-rpp', data)
      this.editReportRP = false
      this.modalVisible = false
      this.confirmLoading = false
    },
    handleCancel() {
      this.modalVisible = false
      this.modalEditVisible = false
    },
    fetchMasterRP(
      params = {
        page: 'all',
        order: 'ASC',
        sortBy: '',
        search: '',
        type: '',
      },
    ) {
      this.loading = true
      this.$store
        .dispatch('admin/FETCH_ALL_REWARD_PUNISHMENT', {
          page: params.page,
          order: params.order,
          sortBy: params.sortBy,
          search: params.search,
          type: params.type,
        })
        .then((data) => {
          // console.log('data fetchDataRewardPunishment', data)
          this.dataMasterRP = data.rewardPunishments.map(row => {
            return {
              id: row.id,
              type: row.type,
              description: row.description,
              score: row.score,
            }
          })
        })
    },
    handleSelectType(value) {
      this.filterType = []
      this.selectedScore = null
      // console.log('value', value)
      this.selectType = value.toLowerCase()
      // console.log('this.selectType', this.selectType)
      const target = this.dataMasterRP.filter(el => el.type === this.selectType)
      // console.log('target', target)
      if (target) {
        this.filterType = target
        if (this.selectedScore === null && this.selectDescription !== null) {
          this.$notification.warning({
            message: 'DANGER!',
            description:
              `Please re-select the ${this.selectType} description`,
          })
        }
      }
    },
    handleSelectDescription(value) {
      // console.log('value', value)
      this.selectDescription = value
      // console.log('this.dataMasterRP', this.dataMasterRP)
      const target = this.dataMasterRP.filter(el => el.id === this.selectDescription)
      console.log('target', target)
      if (target) {
        this.selectedScore = target[0].score
      }
    },
    toEditReportRP() {
      this.editReportRP = true
    },
    saveEdit() {
      this.modalEditVisible = true
    },
    cancelEdit() {
      this.loadingCancel = true
      setTimeout(() => {
        this.editReportRP = false
        this.loadingCancel = false
      }, 2000)
    },
    handleEditChange(value, key, column) {
      const newData = [...this.dataTable]
      const target = newData.filter(item => key === item.key)[0]
      if (target) {
        target[column] = value
        this.savedData = newData
      }
    },
    validateDataInput(data) {
      let isInvalid = false
      let message = ''
      for (let i = 0; i < data.length; i++) {
        // console.log(data[i])
        if (data[i].frequency === '-') {
          data[i].frequency = null
        }
        if (isNaN(Number(data[i].frequency))) {
          isInvalid = true
          message = `Sorry, ${data[i].description}'s frequency must be numbers. ${i}`
          break
        }
      }
      return {
        isInvalid,
        message,
      }
    },
    handleOkUpdate() {
      // console.log(this.savedData)
      const newData = [...this.savedData]
      if (newData.length) {
        const validation = this.validateDataInput(newData)
        if (validation.isInvalid) {
          this.$notification.error({
            message: 'Sorry.',
            description: validation.message,
          })
          this.modalVisible = false
        } else {
          const target = newData.map((row) => {
            return {
              id: row.key,
              id_reward_punishment: row.id_reward_punishment,
              id_general_report: row.id_general_report,
              frequency: Number(row.frequency),
              note: row.note,
            }
          })
          // console.log('Berhasil', target)
          const data = {
            type: 'edit',
            dataEdit: target,
          }
          this.fetchStudentRP()
          this.$emit('child-rpp', data)
          this.editReportRP = false
          this.modalEditVisible = false
          this.confirmLoading = false
        }
      } else {
        this.editReportRP = false
        this.confirmLoading = true
        setTimeout(() => {
          this.$notification.error({
            message: 'Sorry',
            description: 'Reward / Punishment has not been updated',
          })
          this.modalEditVisible = false
          this.confirmLoading = false
        }, 1000)
      }
    },
    deleteRewardPunishment(value, key) {
      this.loadingDelete = true
      this.$confirm({
        title: 'Warning',
        content: (
          <div>Are you sure you want to delete this { this.dataGeneralReport.kelas_tahun_ajaran.murid.nama } reward / punishment ? This student data will be lost</div>
        ),
        onOk: () => {
          const data = {
            type: 'delete',
            id: key,
          }
          this.$emit('child-rpp', data)
          this.loadingDelete = false
        },
        onCancel: () => {
          this.loadingDelete = false
        },
        centered: true,
        icon: 'warning',
        okType: 'danger',
        okText: 'Remove',
      })
    },
  },
  created() {
    this.fetchStudentRP()
    this.fetchMasterRP()
  },
  computed: {
    user() {
      return this.$store.state.user.user
    },
  },
}
</script>

<style lang="scss">
.testBorder {
  border: 2px solid black;
}
</style>
